body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3f1ed;
  
  
}

html {
  scroll-behavior: smooth;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


#name{
  font-family: Helvetica;
  font-size:120px;
  font-weight:bold;
  transition:.2s;
  
}

#name:hover {
  text-shadow: #FC0 18px;
  transition:.2s;
}

#intro{
    border-bottom: solid black 1px;
    padding-bottom:24px;
}

.pad{
  padding-left:24px;
  padding-right:24px;
}

.menu-item{
  display:flex;
  justify-content: space-between;
  margin-bottom:8px;
  
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 8px;
  padding-right:8px;
  border-radius:16px;
  color: black;
}
  /* background-color: white; */


  /* From https://css.glass */

.glassy{
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.5px);
  -webkit-backdrop-filter: blur(5.5px);
  transition: 0.3s;
}

.moreglassy{
  background: rgba(255, 255, 255, 0.5);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(11.6px);
  -webkit-backdrop-filter: blur(11.6px);
}

a {
  text-decoration:none;
  color:rgba(184,148,95);
}

.menu-item:hover{
  background-color: rgba(243,236,220,.8);

}
  

.menu {
  height: 100vh;
  width:320px;
  font-family: Helvetica;
  font-size:16px;
  
  padding-top:8px;
  
  
}

.container {
  position: sticky;
  top:5px;
  display: inline-block;
  align-self: flex-start;
  height:100vh;
  padding-right:16px;
  padding-left:16px;
}

.content {
  margin-left:40px;
  display: flex;
  align-items: center;
  justify-content: center;
  width:100%;
  
}

.flex {
  display:flex;
}

.option{
  /* border:solid red 10px; */
  width:100%;
  margin: 0 auto;
  text-align: center;
  padding-top: 80px;
  /* padding-bottom:80px; */
}

.title{
  font-size: 96px;
  font-family: 'Nanum Myeongjo', serif;
  font-style:italic;
  color:goldenrod;
  /* padding-bottom: px; */

}

button{
  margin:20px;
  width:100px;
}

.proj{
  padding-bottom:140px;
}

.selected{
  border-radius:200px;
  border: 1px solid black;
  padding:16px;
  margin:0px;
  margin-right:16px;
  text-align:center;
  width:240px;
  transition: 0.3s;
}

.unselected{
  border-radius:200px;
  border: 1px solid black;
  padding:16px;
  margin:0px;
  margin-right:16px;
  transition: 0.3s;
}

.unselected:hover{
  background-color:antiquewhite;
  border-color:antiquewhite;
  cursor: pointer;
  transition: 0.1s;
}



.info{
  position:fixed;
  right:16px;
  top:16px;
  border-radius:16px;
  width:400px;
  padding:16px;


}

.headshot{
  width:75%;
  border-radius:32px;
  filter: sepia(75%);
  display:block;
  margin-bottom:16px;

}

.toggle{
  text-align:right;
  padding:16px 16px 0px 16px;
}

.year{
  border:1px solid black;
  border-radius:200px;
  padding:4px 8px;
  width:36px;
  height:16px;
}

#description {
  width:50%;
}

.sec{
  padding-bottom:16px;
  padding-top:32px;
}

.loader{
  background-image: url("https://d2w9rnfcy7mm78.cloudfront.net/5634096/original_325e65711b64c35f28bcb5b7d5ca6a06.gif?1575333792?bc=0");
  height: 100vh;
  text-align:center;

  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  vertical-align: middle;
}

.loader h2{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size:96px;
  color:yellow;
  font-family: 'Times New Roman', Times, serif;
  font-weight:light;
}

.word {
  height:120px;
}

.footer {
  background-color: #f3f1ed;
  width:100;
  display:flex;
  justify-content:space-between;
  padding-top:16px;
  padding-bottom:16px;
  padding-left:16px;
  padding-right:16px;
  vertical-align: middle;
  line-height:24px;
  align-items: center;
  
}

.icons {
  display:flex;
  align-items:center;
}

.footlogo{
  height:64px;
}

.smallfootlogo{
  height:24px;
  margin-left:16px;
}

@media (max-width: 966px) {
  .container {
      display: none;
  }

  iframe {
    height:300px;
  }

  #intro {
    width:100%;
  }

  #description {
    width:80vw;
  }
  #name{
    font-size:72px;
  }

  .word {
    width:85vw;
    margin-left:auto;
    margin-right:auto;
  }
  .title{
    font-size:36px;
  }

  .content{
    margin-left:0px;
  }

  .proj{
    padding-bottom:0px;
  }
}

